import {useState} from "react";
import axios from "axios";

function App() {
  const [acceptEvent, setAcceptEvent] = useState(null)
  
  let path = window.location.pathname.slice(1, window.location.pathname.length)
  
  let userName = ""
  if(path === "859217") userName = "Marcel"
  else if(path === "414974") userName = "Otis"
  else if(path === "741873") userName = "Tim"
  else if(path === "137468") userName = "Andre"
  else return <></>
  
  let html_acceptEvent = (
    <div className="Split">
      <span onClick={() => acceptEventOnClick(true)} className="AC_Button Green">Teilnehmen</span>
      <span onClick={() => acceptEventOnClick(false)} className="AC_Button Red">Absagen</span>
    </div>
  )
  if(acceptEvent === true) {
    html_acceptEvent = (
      <>
        <div>
          <span>Cool <b>{userName}</b>, Du bist dabei <img src={"./cool.svg"} alt={"Cool"} /> ! Bitte fülle das nachfolgende Formular aus, um deine Teilnahme zu bestätigen. Ist bei den Fragen keine passende Auswahl dabei, kannst Du gerne das Textfeld für Anmerkungen und Wünsche benutzen.</span>
        </div>
        <div>
          <label className="Text">Was möchtest Du essen?</label>
          <div className="CB_Wrapper">
            <input type="radio" id="butterchiken" name="essen" value="Butter Chicken" />
            <label htmlFor="butterchiken"><b>Butter Chicken</b><br />(mit Naanbrot, Nudeln und Reis)</label>
            <input type="radio" id="pizza" name="essen" value="Pizza" />
            <label htmlFor="pizza"><b>Pizza</b><br />(Belag in den Wünschen anmerken)</label>
            <input type="radio" id="braten" name="essen" value="Braten" />
            <label htmlFor="braten"><b>Braten</b><br />(Mit Klößen und Rotkohl)</label>
          </div>
        </div>
        <div>
          <label className="Text">Was möchtest Du snacken?</label>
          <div className="CB_Wrapper">
            <input type="checkbox" id="chips" />
            <label htmlFor="chips">Chips</label>
            <input type="checkbox" id="flips" />
            <label htmlFor="flips">Flips</label>
            <input type="checkbox" id="mundm" />
            <label htmlFor="mundm">M&M's</label>
            <input type="checkbox" id="pickup" />
            <label htmlFor="pickup">PiCK UP</label>
            <input type="checkbox" id="erdnuesse" />
            <label htmlFor="erdnuesse">Erdnüsse</label>
            <input type="checkbox" id="gebaeck" />
            <label htmlFor="gebaeck">Gebäck (in den Wünschen anmerken)</label>
            <input type="checkbox" id="gemuese" />
            <label htmlFor="gemuese">Gemüse (in den Wünschen anmerken)</label>
          </div>
        </div>
        <div>
          <label className="Text">Was möchtest Du trinken?</label>
          <div className="CB_Wrapper">
            <input type="checkbox" id="cola" />
            <label htmlFor="cola">Cola</label>
            <input type="checkbox" id="fanta" />
            <label htmlFor="fanta">Fanta</label>
            <input type="checkbox" id="sprite" />
            <label htmlFor="sprite">Sprite</label>
            <input type="checkbox" id="eistee" />
            <label htmlFor="eistee">Eistee</label>
            <input type="checkbox" id="fruchtsaft" />
            <label htmlFor="fruchtsaft">Fruchtsaft</label>
            <input type="checkbox" id="bier" />
            <label htmlFor="bier">Bier</label>
          </div>
        </div>
        <div>
          <label className="Text">Möchtest Du jemanden mitbringen?</label>
          <textarea placeholder="Weitere Personen"></textarea>
        </div>
        <div>
          <label className="Text">Sonstige Anmerkungen und Wünsche:</label>
          <textarea placeholder="Anmerkungen und Wünsche"></textarea>
        </div>
        <div>
          <span>Tritt der Spotify-Playlist bei, damit auch deine Musik gespielt wird!</span><br />
          <span><a className="Spotify" target="_blank" href="https://open.spotify.com/playlist/1zOhL7m7d2pnZK5eUbDHkr?si=d945b51db6684700&pt=2d4fe1bcae937bc42e40670ed98f954a">Hier gehts zur Playlist!</a></span>
        </div>
        <button className="Green" type="submit">Teilnahme bestätigen!</button>
      </>
    )
  }
  else if(acceptEvent === false) {
    html_acceptEvent = (
      <>
        <span>Bist Du dir sicher, dass Du <b>wirklich absagen</b> möchtest?</span>
        <button onClick={(e) => {e.preventDefault(); setAcceptEvent(null)}} className="Green">Nein, bring mich zurück</button>
        <button onClick={submitCancelEvent} className="Red">Ja, ich möchte absagen</button>
      </>
    )
  }
  
  function acceptEventOnClick(accepted) {
    setAcceptEvent(accepted)
  }
  function submitCancelEvent(e) {
    e.preventDefault()
    axios.post("https://api.chill.bmzg.de/cancelEvent", {userName})
  }
  function onFormSubmit(e) {
    e.preventDefault()
    console.log(e.target)
    
    let food = ""
    if(e.target[0].checked) food = "Butter Chicken"
    else if(e.target[1].checked) food = "Pizza"
    else if(e.target[2].checked) food = "Braten"
    
    let snacks = []
    if(e.target[3].checked) snacks.push("Chips")
    if(e.target[4].checked) snacks.push("Flips")
    if(e.target[5].checked) snacks.push("M&M's")
    if(e.target[6].checked) snacks.push("PiCK UP")
    if(e.target[7].checked) snacks.push("Erdnüsse")
    if(e.target[8].checked) snacks.push("Gebäck")
    if(e.target[9].checked) snacks.push("Gemüse")
    
    let drinks = []
    if(e.target[10].checked) drinks.push("Cola")
    if(e.target[11].checked) drinks.push("Fanta")
    if(e.target[12].checked) drinks.push("Sprite")
    if(e.target[13].checked) drinks.push("Eistee")
    if(e.target[14].checked) drinks.push("Fruchtsaft")
    if(e.target[15].checked) drinks.push("Bier")
    
    let additionalPersons = e.target[16].value
    let wishes = e.target[17].value
    
    console.log(food)
    console.log(snacks)
    console.log(drinks)
    console.log(additionalPersons)
    console.log(wishes)
    
    axios
      .post("https://api.chill.bmzg.de/submitForm", {
        userName,
        food,
        snacks,
        drinks,
        additionalPersons,
        wishes
      })
      .then(res => {
        console.log(res)
      })
    
  }
  
  return (
    <div className="App">
      <header>
        <span className="Logo"><span>chill</span> by BMZG</span>
      </header>
      <div className="Container">
        <label className="Info">Allgemine Informationen</label>
        <div className="Info">
          <span>Wer?</span>
          <span>Ich, Lukas...</span>
          <span>Wann?</span>
          <span>02.10.2022 / ab 12:00 Uhr</span>
          <span>Wo?</span>
          <span>August-Hinrichs-Weg 11<br />31627 Rohrsen</span>
          <span>Warum?</span>
          <span>Weil Brownies, weil Spaß!</span>
        </div>
        <form onSubmit={onFormSubmit}>
          <div>
            <span className="Hallo">Hallo <b>{userName}</b></span>
          </div>
          { html_acceptEvent }
        </form>
      </div>
    </div>
  );
}

export default App;
